<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search" :show-exam="false" :showName="false"></search-form>
    <user-table :list="list" @edit="handleEdit" @view="openReport" @recommend="handleRecommend" @sortChange="handleSort" @del="handleDel"></user-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
  </div>
</template>

<script>
// components
import userTable from "./components/user-table"
import searchForm from "../components/search-form"

// utils
import pagination from "@/mixins/pagination"
import service from "./api"
import view from "@/components/viewBar/view"
export default {
  mixins: [pagination, view],
  data() {
    return {
      list: [],
      page: {},
      searchData: {},
      currentPage: 1,
    };
  },
  components: {
    userTable,
    searchForm
  },
  methods: {
    // 初始化
    init(currentPage) {
      let initParams = { pageNum: currentPage, pageSize: 10 };
      return this.getList(initParams);
    },
    // 搜索
    search(data) {
      let params = Object.assign(data, { pageNum: 1 });
      this.searchData = data;
      return this.getList(params);
    },
    // 获取表格数据
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then((res) => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    handleEdit(row) {
      this.$alert(
        `是否确认将用户${row.username || row.mobile}加入到黑名单?`,
        "编辑",
        { showCancelButton: true }
      ).then(() => {
        return service
          .edit({ userId: row.userId || null, type: 1 })
          .then((res) => {
            this.init(this.currentPage);
            this.$message({ type: "success", message: "操作成功！" });
          });
      });
    },
    //推荐
    handleRecommend(row) {
      this.$alert(
        `是否确认将用户${row.username || row.mobile}进行推广?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service
          .recommend({ userId: row.userId || null, isRecommend: 1 })
          .then((res) => {
            this.init(this.currentPage);
            this.$message({ type: "success", message: "操作成功！" });
          });
      }).catch(() => {})
    },
    handleDel(row){
      this.$alert(
        `是否确认将用户"${row.realName || row.mobile}"删除?`,
        "编辑",
        { showCancelButton: true }
      ).then(() => {
        return service
          .delUser({
            userId: row.userId
          })
          .then(res => {
            this.$message({ message: "操作成功!", type: "success" });
            this.init(this.currentPage);
          });
      })
    },
    openReport(row) {
      if (!row.verify_status.real_name) {
        // 未实名认证不可查看报告
        this.$notify({
          type: "error",
          message: "用户未实名认证，暂不能查看用户报告！",
          offset: 400
        });
        return;
      }
      // 打开报告界面
      console.log(row)
      const { href } = this.$router.resolve({
        path: `/risk/detail`,
        query: { userId: row.userId}
      });
      window.open(href, '_blank');
    }
  },
  mounted() {
    this.init(this.currentPage);
  },
};
</script>

<style lang="scss" scoped></style>
