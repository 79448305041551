<template>
  <div class="collapse">
    <template v-if="list.length">
      <!-- 列表第一项 -->
      <div class="collapse-first" :class="{active: list.length > 1}" @click="expand = !expand">
        <span>{{ list[0] }}</span>
        <span class="arrow" :class="expand ? 'el-icon-caret-bottom' : 'el-icon-caret-right' "></span>
      </div>
      <!-- 列表展开项 -->
      <div
        class="collapse-list"
        v-for="(item, index) in list.slice(1)"
        :key="index"
        v-show="expand && item"
      >{{ item }}</div>
    </template>
    <!-- 列表为空 -->
    <template v-else>无</template>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      expand: false // 是否展开
    };
  }
};
</script>

<style lang="scss" scoped>
.collapse {
  padding: 0 10px;
  position: relative;
  &-first {
    &.active {
      cursor: pointer;
      .arrow {
        display: block;
      }
    }
  }
  .arrow {
    position: absolute;
    right: -5px;
    top: 5px;
    display: none;
  }
  &-list {
    padding: 0 10px;
    margin: 5px 0;
  }
}
</style>